import React from 'react';
import tw from 'tailwind-styled-components';

const LoginContainer = tw.div`
  hidden sm:flex flex-col bg-cyan-900 flex-auto bg-cover bg-right-bottom
  border-l border-slate-200 p-8
`;

interface AuthContainerProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const AuthContainer = ({
  children,
  title = 'Log In',
  subtitle = '',
}: AuthContainerProps) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className="flex fixed inset-0 h-screen w-screen">
      <div className="w-full sm:max-w-lg bg-white sm:flex-none p-6 flex flex-col">
        <img
          className="w-48 flex-none"
          src="/assets/images/new-logo.png"
          alt="Rego Engage Logo"
        />
        <div className="flex-none">
          <p className="mt-4 text-sky-800 text-md font-thin">
            Drive Apptio user adoption and engagement with custom email reports
          </p>
        </div>
        <div className="flex-auto flex items-center">
          <div className="w-full">
            <h1 className="text-slate-900 font-medium text-xl mb-1">{title}</h1>
            <span className="flex mb-6 text-slate-500">{subtitle}</span>
            {children}
          </div>
        </div>
        <p className="text-sm text-slate-500">
          ® Rego Consulting {currentYear}
        </p>
      </div>
      <LoginContainer
        style={{
          backgroundImage: 'url(/assets/images/background/engage-journey.webp)',
        }}
      >
        <div className="flex flex-col gap-3 text-slate-200 text-left">
          <p className="text-4xl text-outline-xs glow-3xl-neutral-600">
            Emailer Plugin for Apptio
          </p>
          <p className="text-xl max-w-2xl text-outline-xs glow-4xl-neutral-600">
            Consolidate data from multiple Apptio reports and send directly to
            users via email
          </p>
        </div>
      </LoginContainer>
    </section>
  );
};

export default AuthContainer;
