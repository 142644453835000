import Head from 'next/head';
import React from 'react';

interface PageMetaProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

const PageMeta = ({ title, description, children }: PageMetaProps) => {
  return (
    <Head>
      <title>{title && `${title} | `}Rego Engage</title>
      <meta
        property="og:title"
        content={`${title ? `${title} | ` : ''}Rego Engage`}
        key="title"
      />
      {description && <meta name="description" content={description} />}
      {description && (
        <meta
          property="og:description"
          content={description}
          key="description"
        />
      )}
      {children}
    </Head>
  );
};

export default PageMeta;
