/* eslint-disable @typescript-eslint/no-redeclare */
import {
  email,
  forward,
  type InferInput,
  type InferOutput,
  length,
  minLength,
  object,
  partialCheck,
  pipe,
  regex,
  string,
} from 'valibot';

import appConfig from '../../../../config/app.config';

export const password = pipe(
  string('Please enter a valid password'),
  minLength(
    appConfig.minPasswordLength,
    `Password must be at least ${appConfig.minPasswordLength} characters long`,
  ),
  regex(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter'),
  regex(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter'),
  regex(/(?=.*[0-9])/, 'Password must contain at least one number'),
  regex(
    /(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`=+-])/,
    'Password must contain at least one special character',
  ),
);

export const requestSchema = object({
  email: pipe(
    string('Please enter a valid email'),
    email('Please enter a valid email'),
  ),
});

export type requestSchemaInput = InferInput<typeof requestSchema>;

export const confirmSchema = pipe(
  object({
    code: pipe(
      string('Please enter a valid code'),
      length(6, 'Code must be 6 characters long'),
    ),
    newPassword: password,
    repeatPassword: password,
  }),
  forward(
    partialCheck(
      [['newPassword'], ['repeatPassword']],
      input => input.newPassword === input.repeatPassword,
      'Passwords do not match',
    ),
    ['repeatPassword'],
  ),
);

export type confirmSchemaInput = InferInput<typeof confirmSchema>;

// login
export type loginSchemaInput = InferInput<typeof loginSchema>;
export type loginSchema = InferOutput<typeof loginSchema>;
export const loginSchema = object({
  email: pipe(
    string('Please enter a valid email'),
    email('Please enter a valid email'),
  ),
  password: pipe(
    string('Please enter a valid password'),
    minLength(1, 'Please enter a password'),
  ),
});

// otp

export const otpSchema = object({
  mfaCode: pipe(
    string('Please enter a valid code'),
    length(6, 'Code must be 6 characters long'),
  ),
});

export type otpSchemaInput = InferInput<typeof otpSchema>;

// update pass
//FIXME: There's a bug with the latest version of valibot (0.39) that causes errors in the partialCheck, downgraded to 0.37 until solved
//https://github.com/fabian-hiller/valibot/issues/799

export const updatePasswordSchema = pipe(
  object({
    newPassword: password,
    repeatPassword: password,
  }),
  forward(
    partialCheck(
      [['newPassword'], ['repeatPassword']],
      input => input.newPassword === input.repeatPassword,
      'Passwords do not match',
    ),
    ['repeatPassword'],
  ),
);

export type updatePasswordSchemaInput = InferInput<typeof updatePasswordSchema>;
