import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input, { InputSlotsAndSlotProps } from '@mui/joy/Input';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  get,
  useFormContext,
} from 'react-hook-form';

interface RhfPasswordSlotProps {
  formControl?: React.ComponentPropsWithoutRef<typeof FormControl>;
  formLabel?: React.ComponentPropsWithoutRef<typeof FormLabel>;
  formHelperText?: React.ComponentPropsWithoutRef<typeof FormHelperText>;
}

interface RhfPasswordProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<React.ComponentPropsWithoutRef<typeof Input>, 'slotProps'> {
  name: TName;
  label?: React.ReactNode;
  description?: React.ReactNode;
  visibilityToggle?: boolean;
  min?: number;
  max?: number;
  slotProps?: RhfPasswordSlotProps & InputSlotsAndSlotProps['slotProps'];
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
  ) => void;
}

const RhfPassword = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RhfPasswordProps<TFieldValues, TName>,
) => {
  // Show password state
  const [showPassword, setShowPassword] = useState(false);

  // Load the form context
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>();

  const {
    name,
    label,
    description,
    visibilityToggle,
    slotProps = {},
    onChange,
    ...rest
  } = props;

  const {
    formControl: formControlProps = {},
    formLabel: formLabelProps = {},
    formHelperText: formHelperTextProps = {},
    ...inputSlotProps
  } = slotProps;

  // Handle fullWidth for FormControl
  if (props.fullWidth) {
    formControlProps.sx = {
      ...formControlProps.sx,
      width: '100%',
    };
  }

  // Set default input props
  inputSlotProps.input = {
    min: 0,
    ...inputSlotProps.input,
    type: visibilityToggle && showPassword ? 'text' : 'password',
  };

  // Add visibility toggle button
  if (visibilityToggle) {
    rest.endDecorator = (
      <IconButton onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    );
  }

  const errorMessages = get(errors, name + '.message', undefined) as
    | string
    | undefined;

  const error = !!errorMessages;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl {...formControlProps} error={error}>
          {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
          <Input
            {...rest}
            {...field}
            slotProps={inputSlotProps}
            onChange={event => {
              field.onChange(event.target.value);
              if (typeof onChange === 'function') {
                onChange(event, event.target.value);
              }
            }}
          />
          <FormHelperText {...formHelperTextProps}>
            {errorMessages ?? description}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RhfPassword;
