import Lottie from 'lottie-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import AuthContainer from '../common/auth/auth-container';
import PageMeta from '../common/components/meta/page-meta';
import { LoginForm } from '../common/components/pages/auth/login/form';
import logger from '../common/services/logger';
import { useAuth } from '../common/store/auth/auth.slice';
import loadingAnimation from '../public/assets/animations/loading.lottie.json';

const Login = () => {
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const {
    isAuth,
    isNewPasswordRequired,
    isMFARequired,
    isPasswordResetRequired,
  } = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (isAuth) {
      router.push('/dashboard').catch((err: unknown) => logger.error({ err }));
    } else if (isNewPasswordRequired) {
      router
        .push('/auth/update-pass')
        .catch((err: unknown) => logger.error({ err }));
    } else if (isPasswordResetRequired) {
      router
        .push('/auth/reset-pass')
        .catch((err: unknown) => logger.error({ err }));
    } else if (isMFARequired) {
      router.push('/auth/otp').catch((err: unknown) => logger.error({ err }));
    } else {
      setAppLoading(false);
    }
  }, [
    isNewPasswordRequired,
    router,
    isMFARequired,
    isAuth,
    isPasswordResetRequired,
  ]);

  return (
    <>
      <PageMeta />
      {appLoading ? (
        <main className="flex items-center justify-center h-screen">
          <div className="w-48 h-48">
            <Lottie animationData={loadingAnimation} loop={true} />
          </div>
        </main>
      ) : (
        <AuthContainer>
          <LoginForm />
        </AuthContainer>
      )}
    </>
  );
};

export default Login;
