import { valibotResolver } from '@hookform/resolvers/valibot';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import { FormProvider, useForm } from 'react-hook-form';

import logger from '../../../../services/logger';
import { authenticate } from '../../../../store/auth/actions';
import { useAuth } from '../../../../store/auth/auth.slice';
import { useAppDispatch } from '../../../../store/store';
import safely from '../../../../utils/safely';
import RhfPassword from '../../../form/rhf-password';
import RhfText from '../../../form/rhf-text';
import { loginSchema, loginSchemaInput } from '../schema';

export const LoginForm = () => {
  const form = useForm<loginSchemaInput>({
    resolver: valibotResolver(loginSchema),
    mode: 'onTouched',
  });
  const { handleSubmit } = form;
  const { error: authError, loading } = useAuth();
  const dispatch = useAppDispatch();

  const onHandleSubmit = async (form: loginSchema) => {
    try {
      await dispatch(authenticate(form));
    } catch (err) {
      logger.error(err, 'LoginForm.handleSubmit');
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={safely(
          handleSubmit(onHandleSubmit),
          'LoginForm.handleSubmit',
        )}
        noValidate
        className="grid gap-4"
      >
        {authError && (
          <Box
            sx={{
              color: 'error.main',
              textAlign: 'center',
              fontSize: 'initial',
            }}
          >
            {authError}
          </Box>
        )}
        <RhfText
          name="email"
          label="Email"
          autoComplete="username"
          placeholder="e.g. joe@domain.com"
        />
        <RhfPassword
          name="password"
          label="Password"
          autoComplete="current-password"
        />
        <div className="flex justify-end">
          <Link
            className="text-cyan-600 text-sm hover:underline"
            href="/auth/forgot-pass/request"
          >
            Forgot password?
          </Link>
        </div>
        <div className="first-letter:">
          {!loading ? (
            <Button type="submit" size="lg" disabled={!form.formState.isValid}>
              Sign in
            </Button>
          ) : (
            <IconButton size="large" color="primary">
              <CircularProgress size={24} color="primary" />
            </IconButton>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
